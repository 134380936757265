import ApplicationElement from "./application_element";

// This element makes a whole card clickable, while only providing one link inside it.
// This solution is based on Manuel Matuzović' Web Accessibility Cookbook (Chapter 3.8, p. 83-97)
// and this referenced article: https://css-tricks.com/block-links-the-search-for-a-perfect-solution/#aa-method-4-sprinkle-javascript-on-the-second-method
export default class ClickableCardElement extends ApplicationElement {
  static tagName = "clickable-card";
  static refs = ["link"];
  static events = {
    click: "detectClick",
  };

  detectClick(event) {
    if (event.target.closest("clickable-card :is(a,button)")) return;

    const noTextSelected = !window.getSelection().toString();
    if (noTextSelected) this.refs.link.click();
  }
}
