import "../stylesheets/application.css";
import "@hotwired/turbo-rails";
import Alpine from "alpinejs";
import { alpineComponents } from "@tree-company/admin-components";
import { ClickableCardElement } from "../elements";

document.addEventListener("DOMContentLoaded", () => {
  window.Alpine = Alpine;
  Alpine.start();
});

document.addEventListener("alpine:init", () => {
  Object.entries(alpineComponents).forEach(([name, component]) => {
    Alpine.data(name, component);
  });
});

ClickableCardElement.register();
